import React, { useEffect, useState } from 'react'
import iconFileDownload from "../../../assets/media/icon-file-download.svg";
import iconSearch from "../../../assets/media/icon-search.svg";
import Navbar from '../../navbar';
import Header from '../../header';
import { useDispatch, useSelector } from 'react-redux';
import { fetchApplications } from '../../../store/reducers/applicationSlice';
import Pagination from '../dashboard/appPagination';
import {handleFilesDownload} from '../../../store/reducers/fileSlice';


const PendingApp = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const authToken = user.access_token;

    const applications = useSelector((state) => state.applications);

    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const getallUserdata = useSelector((state) => state.userdata.getalluserdata);
    const [filteredApplication, setFilteredApplication] = useState([]);

    useEffect(() => {
        dispatch(fetchApplications({ accessToken: authToken }));
    }, [authToken, dispatch]);

    useEffect(() => {
        if (applications.data.length > 0 && getallUserdata.length > 0) {
            const filteredApplications = applications.data.filter((item) => {
                const userExists = getallUserdata.some((userdata) =>
                    item.state === "pending_from_dw" && userdata.company_id === user.user.company_id
                );
                return userExists;
            });
            const filtered = filteredApplications.filter((item) =>
                Object.values(item).some((value) =>
                    value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
                )
            );
            setFilteredApplication(filtered);
        }
    }, [searchQuery, applications.data, getallUserdata, user.user.company_id]);

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const getTotalPages = () => {
        if (Number.isInteger(filteredApplication.length / 10)) return filteredApplication.length / 10;
        return Math.floor(filteredApplication.length / 10) + 1;
    };


    const handleDownload = (item) => {
        handleFilesDownload({item,authToken});
    };

    return (
        <div>
            <Navbar />
            <Header />
            <div className='wrapper'>
                <div className='boxAppBoard boxWhite'>
                    <div className='boardHeader'>
                        <div className='boardHeadLeft'>
                            <h2>Pending Applications</h2>
                        </div>
                        <div className='boardHeadRight'>
                            <div className='searchForm'>
                                <input type='text' placeholder='input search text' onChange={handleSearchInputChange} value={searchQuery} />
                                <button><img src={iconSearch} alt='' /></button>
                            </div>
                            <div className='datePicker'>
                            </div>
                        </div>
                    </div>
                    <div className='boardContent'>
                        <div className='boardThead'>
                            <div className='boardCol w-85'>
                                S.NO.
                            </div>
                            <div className='boardCol w-165'>
                                Application No.
                            </div>
                            <div className='boardCol w-adj3'>
                                Application Name
                            </div>
                            <div className='boardCol w-165'>
                                Submitted Date
                            </div>
                            <div className='boardCol w-165'>
                                Downloads
                            </div>
                            <div className='boardCol w-110'>

                            </div>
                        </div>
                        {filteredApplication.map((item, index) => (
                            <div className={`boardTrow ${(currentPage === 1 ? currentPage : ((currentPage - 1) * 10) + 1) <= index + 1 && index < (currentPage === 1 ? 10 : (currentPage * 10)) ? '' : 'd-none'} `} key={item.id}>
                                <div className='boardCol w-85'>
                                    {index + 1}
                                </div>
                                <div className='boardCol w-165'>
                                    {`GB-000000${item.id}`}
                                </div>
                                <div className='boardCol w-adj3'>
                                    <div className='boardTitle'>
                                        <strong>{item.name}</strong>
                                        <span>{item.form}</span>
                                    </div>
                                </div>
                                <div className='boardCol w-165'>
                                    {item?.updated_at.slice(0, 10)}
                                </div>
                                <div className='boardCol w-165'>
                                    <div className='boardDownload'>
                                    <img onClick={() => handleDownload(item.id)} src={iconFileDownload} alt='' />
                                    </div>
                                </div>
                                <div className='boardCol w-110'>

                                </div>
                            </div>
                        ))}
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={getTotalPages}
                    />
                </div>
            </div>
        </div>
    );
};

export default PendingApp;