
export const getFormPageIds = () => {
    const elements = document.querySelectorAll('[id^="page"]');
    const ids = Array.from(elements)
      .filter(element => /^page\d+$/.test(element.id))
      .map(element => element.id);
    return ids;
}


export const goToFormNextPage =(currentPage,pageIds,setCurrentPage) =>{
    if(currentPage+1<=pageIds.length){
        console.log("next page");
        hideDiv(currentPage);
        showDiv(currentPage+1);
        setCurrentPage(currentPage+1);
      }
}

export const goToFormPreviousPage = (currentPage,setCurrentPage) => {
    if(currentPage-1>=1){
        console.log(currentPage)
        hideDiv(currentPage);
        showDiv(currentPage-1);
        setCurrentPage(currentPage-1);
      }
}

export const hideAllPagesExceptCurrentFormPage = (pageIds,currentPage) =>{
    if(pageIds.length>0){
        pageIds.forEach(id => {
            if(id.replace(/^page/, '')!=currentPage){
                const pageDiv = document.getElementById(id);
                if (pageDiv) {
                  pageDiv.style.display = "none";
                  console.log("hiding page");
                }
            }    
          });
    }
}

export const setInputFieldAndFormPageIds = (setFormIds,setPageIds,getPageIds) =>{
    const elements = document.querySelectorAll('[id^="form"]');
    const ids = Array.from(elements)
      .filter(element => /^form\d+_\d+$/.test(element.id))
      .map(element => element.id);
    setFormIds(ids);
    setPageIds(getPageIds());
}

export const loadForm= ({type}) =>{
    const Component = formComponentMap[type];
    return Component ? <Component /> : null;
}

export const setFormFieldData = (formData) =>{
    const formDataMap = JSON.parse(formData);

    for (const [fieldId, value] of Object.entries(formDataMap)) {
        const element = document.getElementById(fieldId);
        if (element) {
            // Set the value of the input field
            if(element.type==="checkbox"){
                element.checked = true;
            }else{
                element.value = value;
            }
        } else {
            console.warn(`Element with id ${fieldId} not found.`);
        }
    }

}
export const hideDiv=(pageNo)=>{
    const pageDiv = document.getElementById("page"+pageNo);
    pageDiv.style.display = "none"; 
}

export const showDiv=(pageNo)=>{
    const pageDiv = document.getElementById("page"+pageNo);
    pageDiv.style.display = ""; 
}

export const formComponentMap = {
    "Virtual reality gaming tax report": "3 Gaming_Licence_NEW_GBB-1-a-1.html",
    "Enhanced gaming permit renewal": "4 Gaming_Licence_FULL_RENEWAL_GBB-1-a-2.html",
    "Interactive gaming tax statement": "5 Gaming_Licence_LIMITED_RENEWAL_GBB-1-a-3.html",
    "Online gaming license application": "6 Proxy_Gaming_Licence_NEW_GBB-1-b-1.html",
    "Progressive gaming license update": "7 Mobile_Gaming_Licence_NEW_GBB-1-b-1.html",
    "Casual gaming tax submission":"8 Restricted_Interactive_Gaming_Licence_NEW_GBB-1-b-1.html",
    "Augmented reality gaming permit":"9 Junket_Operator_Licence_NEW_GBB-7-a-1.html",
    "Remote gaming license extension":"10 Junket_Operator_Licence_RENEWAL_GBB-7-a-2.html",
    "Advanced gaming tax form":"11 Gaming_House_Operator_Licence_NEW_GBB-2-a-1.html",
    "Dynamic gaming license upgrade":"12 Gaming_House_Operator_Licence_RENEWAL_GBB-2-a-2.html",
    "Massive multiplayer gaming permit":"13 Gaming_House_Premises_Licence_NEW_GBB-2-b-1.html",
    "Cloud-based gaming tax return":"14 Gaming_House_Premises_Licence_RENEWAL_GBB-2-b-2.html",
    "Next-gen gaming license renewal":"15 Gaming_House_Agent_Licence_NEW_GBB-2-c-1.html",
    "Real-time gaming tax update":"16 Gaming_House_Agent_Licence_RENEWAL_GBB-2-c-2.html",
    "Immersive gaming license application":"17 Supplier_Licence_NEW_GBB-3-a-1.html",
    "Futuristic gaming tax report":"18 Supplier_Licence_RENEWAL_GBB-3-a-2.html",
    "Blockchain gaming license submission":"20 Key_Employee_Licence_FULL_RENEWAL_GBB-4-a-2.html",
    "AI-powered gaming permit":"21 Key_Employee_Licence_LIMITED_GBB-4-a-3.html",
    "E-sports gaming tax statement":"22 Gaming_Employee_Licence_NEW_PHD_Multi-Jurisdictional_3-0_GBB-4-b-1.html",
    "Modern gaming license extension":"23 Gaming_Employee_Licence_FULL_RENEWAL_GBB-4-b-2.html",
    "Inclusive gaming tax form":"24 Gaming_Employee_Licence_LIMITED_RENEWAL_GBB-4-b-3.html",
    "Virtual economy gaming license":"25 Temporary_Employee_Licence_NEW_GBB-4-c-1.html",
    "Retro gaming tax return":"26 Release_Authorisation_Indemnification_STANDARD_GBB-4-c-2.html",
    "Cryptocurrency gaming permit":"27 Registration_Independent_Testing_Laboratory_NEW_GBB-6-a-1.html",
    "High-definition gaming license":"28 Registration_Independent_Testing_Laboratory_RENEWAL_GBB-6-a-2.html",
    "Microtransaction gaming tax update":"29 Certificate_Suitability_Third_Party_Contractor_NEW_GBB-5-b-2.html",
    "Eco-friendly gaming license renewal":"30 Certificate_Suitability_BED_Hold_Procure_Financial_Interest_in_Licensee_NEW_GBB-5-c-1.html",
    "Customizable gaming tax report":"3 Gaming_Licence_NEW_GBB-1-a-1.html",
    "3D-printed gaming permit":"3 Gaming_Licence_NEW_GBB-1-a-1.html",
    "Experiential gaming license application":"3 Gaming_Licence_NEW_GBB-1-a-1.html",
    "Holographic gaming tax statement":"3 Gaming_Licence_NEW_GBB-1-a-1.html",
    "Geolocation-based gaming license":"3 Gaming_Licence_NEW_GBB-1-a-1.html",
    "Community-driven gaming tax form":"3 Gaming_Licence_NEW_GBB-1-a-1.html",
    "Cybersecurity gaming permit":"3 Gaming_Licence_NEW_GBB-1-a-1.html",
    "AI-enhanced gaming license renewal":"3 Gaming_Licence_NEW_GBB-1-a-1.html",
    "Augmented world gaming tax update":"3 Gaming_Licence_NEW_GBB-1-a-1.html",
    "Multiplatform gaming license":"3 Gaming_Licence_NEW_GBB-1-a-1.html",
    "Social impact gaming tax report":"3 Gaming_Licence_NEW_GBB-1-a-1.html",
    "Quantum computing gaming permit":"3 Gaming_Licence_NEW_GBB-1-a-1.html",
    "Immersive universe gaming license":"3 Gaming_Licence_NEW_GBB-1-a-1.html",
    "In-game purchase gaming tax submission":"3 Gaming_Licence_NEW_GBB-1-a-1.html",
    "Virtual commerce gaming permit":"3 Gaming_Licence_NEW_GBB-1-a-1.html",
    "Collaborative gaming license application":"3 Gaming_Licence_NEW_GBB-1-a-1.html",
    "Blockchain-enabled gaming tax statement":"3 Gaming_Licence_NEW_GBB-1-a-1.html",
    "Next-level gaming permit":"3 Gaming_Licence_NEW_GBB-1-a-1.html",
    "Biofeedback gaming license":"3 Gaming_Licence_NEW_GBB-1-a-1.html",
    "Interactive narrative gaming tax form":"3 Gaming_Licence_NEW_GBB-1-a-1.html",
    "Self-learning gaming permit":"3 Gaming_Licence_NEW_GBB-1-a-1.html",
    "Futuristic gaming license renewal":"3 Gaming_Licence_NEW_GBB-1-a-1.html",
    "Environmentally conscious gaming tax update":"3 Gaming_Licence_NEW_GBB-1-a-1.html",
    "Gaming License & Tax":"3 Gaming_Licence_NEW_GBB-1-a-1.html"

}