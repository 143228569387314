import React ,{useState,useRef} from 'react';
import { IoChevronBackSharp, IoChevronForwardSharp } from "react-icons/io5"

const FormHeader =(props)=>{
    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null);
    const handleGoPrev = (event) => {
        //change page
        console.log("Go previous");
        props.previousPage(event);

      }
      const handleGoForw = (event) => {
         //change page
         props.nextPage(event);
         console.log("Go Forward");
      }
      const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles((prevData) => [...prevData, ...selectedFiles]);
        // props.setUploadedFiles(selectedFiles);
        props.setUploadedFiles((prevData) => [...prevData, ...selectedFiles]);

    };

    const handleButtonClick = () => {
        fileInputRef.current.click();  // Trigger the file input click
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;
        if(file){
            console.log(file);
            const newFileName = 'formSignatureUser.'+file.name.split('.').pop(); 
            const newFile = new File([file], newFileName, { type: file.type });
            props.setUploadedFiles((prevData) => [...prevData, newFile]);
            setFiles((prevData) => [...prevData, newFile]);
            console.log(files);
        }
        const reader = new FileReader();
        reader.onload = (e) => {
            props.signatureIds.forEach(id => {
                const imageInput = document.getElementById(id);
                if (imageInput && imageInput.type === 'image') {
                    // Directly set the src of the image input
                    let originalWidth = window.getComputedStyle(imageInput).width.replace("px","")
                    let originalTop = window.getComputedStyle(imageInput).top.replace("px","")
                    let height = (originalWidth*114)/179
                    imageInput.src = e.target.result;
                    props.setImageData(e.target.result);
                    imageInput.style.height =height+"px";
                    imageInput.style.top = (originalTop-height+20)+"px";
                    imageInput.style.objectFit = 'cover'; // Optional: Ensures the image covers the entire area
                }
            });
        };
        reader.readAsDataURL(file);
    };
     // State to control if MyComponent is displayed
  const [showComponent, setShowComponent] = useState(false);
  const handleClick = () => {
    setShowComponent(true); // Set state to true to render MyComponent
  };
    return(
        <>
           <div className='AppFormBar noShadow'>
                    <div className='title'>
                        <input
                            value={props.applicationName}
                            style={{
                                width: "400px",
                                background: "#323639",
                                border: "solid 0px #ccc",
                                borderRadius: "5px",
                                padding: "5px",
                                fontWeight: "600",
                                fontSize: "16px",
                                lineHeight: "22px",
                                color: "#fff",
                            }} />
                    </div>
                    
                    {/* {props.setUploadedFiles &&
                    <div>
                         <input
                            type="file"
                            onChange={handleFileChange}
                            ref={fileInputRef}
                            multiple  // Allow multiple file selection
                            style={{ display: 'none' }}  // Hide the file input
                        />
                        <button type="button" onClick={handleButtonClick}>
                            {files.length>0 ? "Selected Files " + files.length : "Select Files"}
                        </button>
                        <input 
                        type="file" 
                        accept="image/*" 
                        onChange={handleImageUpload} 
                        />
                    </div>
                  
                    } */}
                     <button
                                type='submit'
                                style={{
                                    backgroundColor: '#114df2',
                                    color: 'white',
                                    padding: '5px',
                                    float: 'right',
                                    border: '1px solid #114df2',
                                    borderRadius: 5,
                                    width: '15%',
                                    height: '15%'

                                }}
                                onClick={(()=>props.saveToDrafts("drafts"))}
                            >Save to Draft</button>
                            {/* <button
                                type='submit'
                                style={{
                                    backgroundColor: '#114df2',
                                    color: 'white',
                                    padding: '5px',
                                    float: 'right',
                                    border: '1px solid #114df2',
                                    borderRadius: 5,
                                    width: '15%',
                                    height: '15%'

                                }}
                                onClick={handleClick}
                            >upload</button> */}
                    <button
                                type='submit'
                                style={{
                                    backgroundColor: '#114df2',
                                    color: 'white',
                                    padding: '5px',
                                    float: 'right',
                                    border: '1px solid #114df2',
                                    borderRadius: 5,
                                    width: '15%',
                                    height: '15%'

                                }}
                                onClick={(props.handleSubmitWithLoading)}
                                // disabled={isLoading}
                            >
                                {/* {isLoading ? (
                                    <>
                                        <div className="text-xs spinner-border text-light" style={{ width: '1rem', height: '1rem' }} role="status"></div>
                                        <span className="sr-only ml-2">Loading...</span> 
                                    </>
                                ) : (
                                    "Submit"
                                )} */}
                                Submit
                            </button>
                    <div className='pagination'>
                        <div className='pageNum d-flex align-items-center'>
                            <IoChevronBackSharp onClick={handleGoPrev} />
                            <span className='pageCurrent d-flex align-items-center justify-content-center' style={{ width: 50, height: 50, border:0, textAlign:'center', color:'#fff' }} >{props.currentPage}</span> <span className='slash'>{`/`}<span style={{ marginLeft: 10 }}></span></span>{props.totalPages}
                            <IoChevronForwardSharp onClick={handleGoForw} />
                        </div>
                    </div>
                </div>
        </>
    )
}

export default FormHeader;