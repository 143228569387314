import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes, Navigate } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import "./App.css";
import Login from "./components/pages/login";
import SignUp from "./components/pages/signup";
import SignUp2 from "./components/pages/signup/SignUp2";
import SignUp3 from "./components/pages/signup/SignUp3";
import Forget from "./components/pages/forget";
import Reset from "./components/pages/reset";

import Profile from "./components/pages/profile";

import { store, persistor } from './store';
import { Provider, useDispatch, useSelector } from "react-redux";

import Dashboard2 from "./components/pages/dashboard/Dashboard2";

import Feedback from "./components/pages/feedback";
import Draft from "./components/pages/applications/draft";
import Certifications from "./components/pages/certifications";
import FileCabinet from "./components/pages/file-cabinet";
import GamingLicence from "./components/pages/file-cabinet/gaming-licence";

// App Components
import AppDiscrepancies from "./components/pages/applications/appDiscrepancies";
import AppFormsAdmin from "./components/pages/appforms/appformAdmin";
import AppDashboard from "./components/pages/dashboard/appDashboard";
import FormStep1 from "./components/pages/appforms/formstep1";
import AppProcessApp from "./components/pages/applications/app-in-process";
import AppPendingApp from "./components/pages/applications/pending";
import AppRejectedApp from "./components/pages/applications/app-rejected";
import AppApprovedApp from "./components/pages/applications/app-approved";
import AppRenewals from "./components/pages/applications/appRenewals";
import AppDrafts from "./components/pages/applications/appDraft";
import AppEmployees from "./components/pages/appEmployees";
// import AppForm from "./components/pages/appForm";
import AppForm from "./components/pages/appForm/indexFormCopy";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppEdit from "./components/pages/applications/app-edit";
import Notifications from "./components/pages/notifications";
import { fetchNotifications } from "./store/reducers/notificationSlice";
import Discrepancies from "./components/pages/applications/discrepancies";

const MyRouter = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  useEffect(()=>{
    if(user){
    dispatch(fetchNotifications(user.access_token));
    }
  },[])
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forget-password" element={!user ? <Navigate to="/dashboard" replace /> : <Forget />} />
        {/* <Route path="/signup-step1" element={!user ? <Navigate to="/dashboard" replace /> : <SignUp />} />
        <Route path="/signup-step3" element={!user ? <Navigate to="/dashboard" replace /> : <SignUp2 />} />
        <Route path="/signup-step3" element={!user ? <Navigate to="/dashboard" replace /> : <SignUp3 />} /> */}
        <Route path="/reset-password" element={<Reset />} />
        <Route path="/signup-step1" element={<SignUp />} />
        <Route path="/signup-step2" element={<SignUp2 />} />
        <Route path="/signup-step3" element={<SignUp3 />} />

        {/* Dynamic Components */}
        {/* <Route path="/forms" element={user ? <AppFormsAdmin /> : <Login />} /> */}
        <Route path="/forms/*" element={user ? <AppFormsAdmin /> : <Login />} />
        {/* <Route path="/in-process-applications" element={user ? <AppApplications /> : <Login />} /> */}
        <Route path="/in-process-applications" element={user ? <AppProcessApp /> : <Login />} />
        {/* <Route path="/in-process-applications" element={user ? <ProcessApp /> : <Login />} /> */}
        <Route path="/pending-applications" element={user ? <AppPendingApp /> : <Login />} />
        {/* <Route path="/pending-applications" element={user ? <AppApplications /> : <Login />} /> */}
        <Route path="/rejected-applications" element={user ? <AppRejectedApp /> : <Login />} />
        {/* <Route path="/rejected-applications" element={user ? <AppApplications /> : <Login />} /> */}
        <Route path="/approved-applications" element={user ? <AppApprovedApp /> : <Login />} />
        <Route path="/app-form" element={user ? <AppForm /> : <Login />} />
        <Route path="/application-edit/:id" element={user ? <AppEdit /> : <Login />} />
        
        {/* <Route path="/approved-applications" element={user ? <AppApplications /> : <Login />} /> */}
        <Route path="/dashboard" element={user ? <AppDashboard /> : <Login />} />
        <Route path="/formsteps" element={user ? <FormStep1 /> : <Login />} />
        <Route path="/discrepancies" element={user ? <AppDiscrepancies /> : <Login />} />
        <Route path= "/view-discrepancies/:id" element={user ? <Discrepancies/> : <Login/>}/>

        {/*Static Components*/}
        <Route path="/profile" element={user ? <Profile /> : <Login />} />
        <Route path="/employees" element={user ? <AppEmployees /> : <Login />} />
        {/* <Route path="/employees" element={user ? <Employees /> : <Login />} /> */}
        <Route
          path="/dashboard-2"
          element={user ? <Dashboard2 /> : <Login />}
        />
        <Route path="/feedback" element={user ? <Feedback /> : <Login />} />
        {/* <Route path="/in-process-applications" element={user ? <ProcessApp /> : <Login />} />
        <Route path="/dashboard" element={user ? <Dashboard /> : <Login />} />
        <Route path="/pending-applications" element={user ? <PendingApp /> : <Login />} />
        <Route path="/rejected-applications" element={user ? <RejectedApp /> : <Login />} />
        <Route path="/approved-applications" element={user ? <ApprovedApp /> : <Login />} /> */}
        {/* <Route path="/discrepancies" element={user ? <Discrepancies /> : <Login />} /> */}
        <Route path="/draft" element={user ? <AppDrafts /> : <Login />} />
        <Route path="/draft/:id" element={user ? <Draft /> : <Login />} />
        {/* <Route path="/draft" element={user ? <Draft /> : <Login />} /> */}
        <Route path="/renewals" element={user ? <AppRenewals /> : <Login />} />
        {/* <Route path="/renewals" element={user ? <Renewals /> : <Login />} /> */}
        <Route
          path="/certifications"
          element={user ? <Certifications /> : <Login />}
        />
        <Route
          path="/file-cabinet"
          element={user ? <FileCabinet /> : <Login />}
        />
        <Route
          path="/gaming-licence"
          element={user ? <GamingLicence /> : <Login />}
        />
         <Route path="/notification" element={user ? <Notifications/>: <Login />} />
      </Routes>
    </Router> 
  );
};

function App() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <MyRouter />
          <ToastContainer />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
}

export default App;
