import React, { useEffect, useState } from 'react';
import Navbar from '../../navbar';
import Header from '../../header';
import { useDispatch, useSelector } from 'react-redux';
import {fetchApplication} from '../../../store/reducers/applicationSlice'
import classes from '../appForm/style.module.css'
import { useParams } from 'react-router-dom';
import {setInputFieldAndFormPageIds,setFormFieldData,hideAllPagesExceptCurrentFormPage,getFormPageIds} from  '../../../utility/utility';
import { updateApplication,safePdf } from '../../../store/reducers/applicationSlice';
import {getSignature} from '../../../store/reducers/fileSlice';
import DescripanciesBox from './discepancies-box';
import { loadForm } from '../../../utility/utility';
// import Form from './form';
import FormHeader from '../forms/formHeader/formHeader';
import AddAttachment from './attachments';
import FormLoader from '../forms/formLoader';
import { useNavigate } from "react-router-dom";

const Descripancies = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const authToken = user.access_token;
    const params = useParams();
    const application = useSelector((state) => state.applications.application)
    const [pageIds,setPageIds] = useState([]);
    const [currentPage,setCurrentPage] = useState(1);
    const [formIds, setFormIds] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [applicationDicrepancies,setApplicationDiscrepancies] = useState(null);
    const [inputFields, setInputFields] = useState({});
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [signatureIds,setSignatureIds] = useState([]);
    const [imageData, setImageData] = useState('');
    const [signatureImage,setSignatureImage] = useState('')
    const [showAttachments,setShowAttachments] = useState(false);
    const [files, setFiles] = useState({})
    const [fileNames, setFileNames] = useState({})
    const navigate = useNavigate();


    useEffect(()=>{
        if(dispatch && params.id && authToken){
            dispatch(fetchApplication({id:params.id,accessToken: authToken }));
        }
    },[dispatch,params.id,authToken])


    useEffect(() => {
        const runAsyncFunctions = async () => {
            if (isLoaded) {
                await setFormFieldData(application.json_data);
                let signature =await getSignature({id:params.id,authToken});
                setSignatureImage(signature);
            }
        };
    
        runAsyncFunctions();
    }, [isLoaded]);

    useEffect(()=>{
        if(application && isLoaded){
            console.log(application);
            setApplicationDiscrepancies(application.discrepancies);
            console.log(application.discrepancies);
        }
        if(application?.json_data && isLoaded){
            setInputFields( JSON.parse(application.json_data));
        }
    },[application,isLoaded])
    useEffect(()=>{
      console.log("signatureImage = ",signatureImage);
      if(signatureImage!='' && signatureIds.length>0){
        signatureIds.forEach(id => {
          let imageInput = document.getElementById(id);
          if (imageInput && imageInput.type === 'image') {
              let originalWidth = window.getComputedStyle(imageInput).width.replace("px","")
              console.log("originalWidth = ",originalWidth);
              let originalTop = window.getComputedStyle(imageInput).top.replace("px","")
              console.log("originalTop = ",originalTop);
              let height = (originalWidth*114)/179;
              console.log("height = ",height);
              imageInput.style.height =height+"px";
              imageInput.style.top = (originalTop-height)+20+"px";
              console.log("imageInput.style.top = ",imageInput.style.top);
              imageInput.src = signatureImage;
              imageInput.style.objectFit = 'cover'; // Optional: Ensures the image covers the entire area
              setImageData(signatureImage);
          }
      });
      }
    },[signatureImage])
    
    useEffect(()=>{
        if(pageIds){
            hideAllPagesExceptCurrentFormPage(pageIds, currentPage);
        }
    },[pageIds])

    useEffect(() => {
        if (formIds.length > 0) {
          attachEventHandlers();
        }
      }, [formIds.length]);

    const attachEventHandlers = () => {
      let tempSignatureIds = []

          formIds.forEach(id => {
            const inputField = document.getElementById(id);
            if(inputField.type === 'image'){
              tempSignatureIds.push(id);

            }else if(inputField.type==='checkbox'){
              console.log("checkbox");
              inputField.addEventListener('change', handleCheckBoxChange)
            }else{
              inputField.addEventListener('change', handleInputChange);
            }
          });
          setSignatureIds(tempSignatureIds);
        };
    const handleInputChange = (event) => {
        const { id, value } = event.target;

        setInputFields(prevInputFields => ({
        ...prevInputFields,
        [id]: value,
        }));
    
    };
    const handleCheckBoxChange = (event) => {
      const { id, checked, value } = event.target;
    
      setInputFields(prevInputFields => {
        // If the checkbox is checked, add it to inputFields
        if (checked) {
          return {
            ...prevInputFields,
            [id]: value,
          };
        } else {
          // If the checkbox is unchecked, remove it from inputFields
          const { [id]: removedValue, ...remainingFields } = prevInputFields;
          return remainingFields;
        }
      })};
    const handleSubmitWithLoading = (e) => {
 
        console.log(inputFields);
        let htmlForm = document.getElementById("DocumentPDF").outerHTML; 
        htmlForm =  removeDisplayNoneByIds(htmlForm,pageIds);
 
        dispatch( updateApplication({
            accessToken:authToken,
            id: params.id,
            json_data : inputFields
            }))
        dispatch(safePdf({
            htmlForm,
            user_id: user.user.id,
            id: params.id,
            token: authToken
          }))
          navigate('/in-process-applications');

    };

    function removeDisplayNoneByIds  (htmlContent, ids) {
      // Parse the HTML string into a DOM structure
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');
    
      // Loop through the array of IDs
      ids.forEach(id => {
        const element = doc.getElementById(id);
        if (element && element.style.display === 'none') {
          element.style.display = '';  // Remove the 'display: none' style
        }
      });
      // JSON.parse(formData);
      Object.entries(inputFields).forEach(([id, value]) => {
          // Get the input element by ID
          const element = doc.getElementById(id);
          
          // Check if the element exists and is an input field
          if (element && element.tagName.toLowerCase() === 'input') {
                  // Set the value of the input field
              element.setAttribute('value', value);
          }
          if (element.type === 'checkbox') {
            // Set the checked state of the checkbox
            element.setAttribute('checked', true);
          }
      });

      const modifiedHtmlContent = new XMLSerializer().serializeToString(doc);
      return modifiedHtmlContent;
    };

    const previousPage = () =>{
      if(currentPage == pageIds.length+1){
        setShowAttachments(false);
        showDiv(currentPage-1);
        setCurrentPage(currentPage-1)
      }else{
        if(currentPage-1>=1){
          hideDiv(currentPage);
          showDiv(currentPage-1);
          setCurrentPage(currentPage-1);
        }  
      }
    }

    
      const nextPage = () =>{
        if(currentPage+1<=pageIds.length){
          hideDiv(currentPage);
          showDiv(currentPage+1);
          setCurrentPage(currentPage+1);
        }else if (currentPage+1<=pageIds.length+1){
          hideDiv(currentPage);
          setCurrentPage(currentPage+1);
          setShowAttachments(true);
        }
      }
      const hideDiv=(pageNo)=>{
        const pageDiv = document.getElementById("page"+pageNo);
        pageDiv.style.display = "none"; 
      }
  
      const showDiv=(pageNo)=>{
        const pageDiv = document.getElementById("page"+pageNo);
        pageDiv.style.display = ""; 
      }

      useEffect(()=>{
        console.log(inputFields);
      },[inputFields])
      useEffect(()=>{
        if(files){
          setUploadedFiles((prevUploadedFiles) => [
            ...prevUploadedFiles,
            ...Object.values(files) // Extract values (files) from the `files` object
          ]);
        }
      },[files])
  
    return (
        <div>
          <Navbar />
          <Header />
          <div className='wrapper'>
                <div className='boxAppBoard boxWhite'>
                <div className="stickyFormHeader">                    
                <FormHeader applicationName={application.name} nextPage={nextPage} previousPage={previousPage} 
                currentPage={currentPage} totalPages={pageIds.length+1} handleSubmitWithLoading={handleSubmitWithLoading}
                setUploadedFiles={setUploadedFiles} signatureIds={signatureIds} setImageData={setImageData}/>
                </div>

                    <div className={classes['board-content']}>
                        <div className='disarea'>
                            {applicationDicrepancies && <DescripanciesBox descrepancies={applicationDicrepancies} setCurrentPage={setCurrentPage} currentPage={currentPage}/>}
                            {/* {LoadedComponent} */}
                            <FormLoader applicationName={application.name} setFormIds={setFormIds} setPageIds={setPageIds} setIsLoaded={setIsLoaded}/>
                            {showAttachments && <AddAttachment signatureIds={signatureIds} files={files} setFileNames={setFileNames}
                    setFiles={setFiles} fileNames={fileNames}/>} 
                                            </div>
                    </div>
                   
                </div>
            </div>
        </div>
    )
}
export default Descripancies