import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { showError, showSuccess } from '../../helpers/toast';

const API = axios.create({

  baseURL: process.env.REACT_APP_BACKEND_URL // Replace this with your API's base URL

  // Other default configurations (headers, timeout, etc.) can be set here if needed
});

API.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      showError(error.response?.data?.error ?? error.message);
      return Promise.reject(error);
    }
  );
  
  export const handleFilesDownload = async (data) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}file/${data.item}/downloadapplicationfiles`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/zip',
              'Authorization': `${data.authToken}`,
            },
          });
      
          if (!response.ok) {
            throw new Error('Failed to fetch files');
          }
      
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'files.zip';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading files:', error);
}
  }

  export const getSignature = async (data) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}file/${data.id}/applicationsignature`, {
            method: 'GET',
            headers: {
              'Authorization': `${data.authToken}`,
            },
          });
      
          if (!response.ok) {
            return
          }
      
          const imageBlob = await response.blob();

          // // Create a local URL for the fetched image blob
          // getImageAsBase64('YOUR_IMAGE_URL')
          const imageObjectURL = URL.createObjectURL(imageBlob);
          return  getImageAsBase64(imageObjectURL);

    } catch (error) {
      console.error('Error downloading files:', error);
}
  }

  export  const downloadCertificate = async (id, authToken) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}file/${id}/downloadCertificate`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authToken}`,
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to download the signature file');
      }
  
      // Convert the response to a Blob object
      const blob = await response.blob();
  
      // Create a temporary URL for the Blob object
      const url = window.URL.createObjectURL(new Blob([blob]));
  
      // Create a temporary anchor element to trigger the download
      const link = document.createElement('a');
      link.href = url;
  
      // Use the file name returned from the backend
      const contentDisposition = response.headers.get('Content-Disposition');
      const fileName = contentDisposition?.split('filename=')[1]?.replace(/['"]/g, '') || 'signature.png';
  
      link.setAttribute('download', fileName); // File name for download
  
      // Append link to the document, click it to trigger the download, and then remove it
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading signature file:', error);
    }
  };
  const getImageAsBase64 = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};